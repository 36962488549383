import React from "react";
import data from "../../../data/syllabus";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";

export default function SyllabusPage() {
  return (
    <Layout>
      <SEO
        title="School Syllabus | Chinmaya Vidyalaya Vasant Vihar"
        description="View regularly updated Class-wise syllabus for the 2021-22 session of Chinmaya Vidyalaya Vasant Vihar"
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords="Chinmaya vidyalaya vasant vihar, chinmaya trust, chinmaya mission, online vidyalaya,online admission,schools in delhi,online registrations"
      />
      <div className="syllabus">
        <h1 className="heading">Syllabus</h1>
        <br />

        <p>
          Class-wise syllabus for the 2023-24 session is available to view
          below. This section is regularly updated.
        </p>
        <div className="syllabus__table">
          <div className="syllabus__table__row syllabus__table__row--heading">
            <div className="syllabus__table__col1">Classes</div>
            {/* <div className="syllabus__table__col2">Download</div> */}
          </div>
          {data.map((item, i) => (
            <div key={`row-${i}`} className="syllabus__table__row">
              <div className="syllabus__table__col1">{item.name}</div>
              <div className="syllabus__table__col2">
                <a href={item.subjectPdf} download>
                  Download <i className="fas fa-file-download"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
